.ts-nav-menu__item {
  fill: #a09881;
  color: #a09881;
  height: 40px;
  padding: 0 15px;
  border-radius: 12px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: solid 0ts-5px transparent;
  position: relative;
  cursor: pointer;
  line-height: 1;
}

.ts-main a {
  color: #f7b500;
}

.ts-nav-menu__item .count-new-messages {
  right: 110px;
  left: auto;
}

.ts-nav-menu__item svg {
  fill: #a09881;
}

.ts-nav-menu__item:hover {
  border: solid 0ts-5px #fff;
  background-color: #a09881;
  color: #fff;
  fill: #fff;
}

.ts-nav-menu__item:active {
  border: solid 0ts-5px #e9f1f6;
  background-color: #a09881;
  fill: #fff;
  color: #fff;
}
.ts-nav-menu__item span.active,
.ts-nav-menu__item.active span.no-active {
  display: none;
}

.ts-nav-menu__item.active span.active,
.ts-nav-menu__item span.ts-no-active {
  display: flex;
}

.ts-nav-menu__item.active {
  fill: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  border: solid 0ts-5px #a09881;
  background-color: #a09881;
}

.ts-nav-menu__item.active svg {
  fill: #fff;
}

.ts-nav-menu__item:hover svg {
  fill: #fff;
}

.ts-nav-menu__item.new-notifications:before {
  content: '';
  display: block;
  position: absolute;
  left: 31px;
  top: 3px;
  border-radius: 8px;
  background-color: #ec004a;
  width: 11px;
  height: 11px;
}

.ts-card {
  background-color: #322f28;
  border: solid 0.5px #322f28;
  border-radius: 17px;
  overflow: hidden;
  margin-top: 20px;
  max-width: 316px;
  min-width: 316px;
  margin-left: 21px;
  cursor: pointer;
  text-decoration: none;
}
.ts-card .card_img {
  background: #322f28;
}
.ts-card .card_content {
  background: #322f28;
  min-height: 70px;
}
.ts-card .card_title {
  color: #fff;
  margin-bottom: 0;
}
.people_card_location {
  color: #fff;
  font-size: 16px;
  margin-right: 120px;
  padding: 0 14px 0;
}
.ts-card a.card_link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.ts-card .card_actions {
  margin-bottom: -60px;
}

.ts-card .card_actions .btn_favorites {
  background-color: #a09881;
}

.ts-card .card_actions .btn_favorites.active {
  background-color: #ec0000;
}

.ts-card .card_actions .btn_chat {
  background-color: #f7b500;
}
.ts-card .card_actions .btn_chat:hover {
  background-color: #f7a900;
}
.ts-card .card_actions .btn_favorites:hover {
  background-color: #58564f;
}
.ts-dialog {
  display: flex;
  border-radius: 17px;
  padding: 0 0 0 9px;
  position: relative;
  margin-bottom: 6px;
  cursor: pointer;
}
.ts-dialog.new {
  background-color: #322f28;
}
.ts-dialog.current {
  background-color: #dbe6ee;
}
.ts-dialog.current .dialog_content {
  border-bottom: 1px solid transparent;
}
.ts-dialog.new .dialog_content {
  border: none;
}
.ts-dialog .dialog_content {
  border-bottom: 1px solid #322f28;
}
.ts-dialog .dialog_location {
  color: #a09881;
}
.ts-dialog .dialog_title {
  color: #a09881;
}
.ts-dialog .dialog_preview {
  color: #a09881;
}

.ts-dialog.new .dialog_location {
  color: #fff;
}
.ts-dialog.new .dialog_title {
  color: #fff;
}
.ts-dialog.new .dialog_preview {
  color: #fff;
}
.ts-messages-tabs {
  display: flex;
  margin-bottom: 23px;
  justify-content: space-between;
  align-items: flex-end;
}

.ts-messages-tabs .messages-tab {
  color: #a09881;
  cursor: pointer;
}

.ts-messages-tabs .messages-tab.active {
  color: #f7b500;
  background-color: #322f28;
}

.ts-messages-area__head {
  height: 140px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  background: #a09881;
}
.ts-popup_content__chat {
  text-align: left;
  background-color: #403d34;
}

.ts-popup_content__chat.scroll {
  border: none;
}

.ts-popup_footer__chat {
  padding: 0;
  background-color: #403d34;
}

.live_text {
  background-color: red;
  font-weight: 500;
  padding: 1px 8px;
  position: absolute;
  font-style: italic;
  left: 10px;
  top: 10px;
  z-index: 10;
  border-radius: 5px;
}

.ts-messages-area__head .btn_close svg {
  height: 18px;
  fill: #ffffff;
}

.message_text.ts-from {
  background-color: #f7b500;
  color: #000000;
}

.message_text.ts-to {
  background-color: #a09881;
  color: #ffffff;
}

.messages_list.ts-to svg {
  position: absolute;
  right: -6px;
  bottom: 0;
}

.messages_list.ts-from svg {
  position: absolute;
  left: -6px;
  bottom: 0;
}

.messages_list.ts-from {
  margin-right: auto;
}

.messages_list.ts-to {
  margin-left: auto;
}
.message_text.last_message.ts-from:before {
  left: -6px;
  border-left: 20px solid #e9f1f6;
  border-bottom-right-radius: 18px 18px;
  -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px);
}

.message_text.last_message.ts-from:after {
  left: 15px;
  width: 15px;
  background: #f7b500;
  border-bottom-right-radius: 8px;
  -webkit-transform: translate(-30px, -3px);
  transform: translate(-30px, -3px);
  height: 20px;
}

.message_text.last_message.ts-to:before {
  right: -6px;
  border-bottom-left-radius: 18px 18px;
  -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px);
}

.message_text.last_message.ts-to:before {
  border-right: 20px solid #f7b500;
}

.message_text.last_message.error:before {
  border-right: 20px solid #f78888;
}

.message_text.last_message.ts-to:after {
  right: -45px;
  left: auto;
  width: 15px;
  background: #a09881;
  border-bottom-left-radius: 8px;
  -webkit-transform: translate(-30px, -3px);
  transform: translate(-30px, -3px);
  z-index: 0;
  height: 20px;
}

.live_chat_photo {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.live_chat_photo.no_photo {
  background: #c3d2db url('/img/no-photo.svg') no-repeat center bottom;
  background-size: 47px;
  border-radius: 50%;
}

.message_name {
  font-size: 12px;
  color: #403d34;
  margin-bottom: 2px;
}

.live_message_wrap {
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.hearts_title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 10px;
}
.hearts_wrap {
  padding-left: 20px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.heart_item {
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 50px;
  background-color: #f7b500;
  color: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.heart_count {
  margin-left: 7px;
  font-weight: 900;
  color: white;
  font-size: 18px;
}
.heart_text {
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
}
.ts-message_translate_action {
  color: var(--primary-color-active);
  font-weight: 400;
  padding: 0 15px;
  cursor: pointer;
}
.ts-translate_wrap {
  position: absolute;
  left: 60px;
  bottom: -25px;
}
.ts-popup_body__profile {
  background-color: #a09881;
}

.ts-popup_body__profile .carousel-arrow {
  background: rgb(160, 152, 129);
}
.ts-popup_body__profile .popup_head {
  background-color: #a09881;
}
.ts-popup_body__profile .popup_content {
  border-top: solid 0.5px #a09881;
  border-bottom: solid 0.5px #a09881;
}
.ts-popup_body__profile .popup_footer {
  background-color: #403d34;
}
.ts-popup_body__profile .btn_favorites {
  background-color: #f7b500;
}
.ts-popup_body__profile .btn_favorites:hover {
  background-color: #f7a900 !important;
}
.ts-popup_body__profile .btn_favorites.active:hover .ts-undo {
  color: #fff !important;
}
.ts-undo {
  color: #f7b500;
  font-weight: 700 !important;
}
.ts-popup_body__profile .btn_favorites.active {
  color: #fff !important;
  font-weight: 400 !important;
}
.ts-popup_body__profile .btn_primary {
  background-color: #f7b500;
  color: white;
}
.ts-popup_body__profile .btn_chat {
  background-color: #f7b500;
  color: white;
}
.ts-popup_body__profile .btn_chat:hover {
  background-color: #f7a900 !important;
}
.ts-popup_body__profile .btn_primary:hover {
  background-color: #f7a900 !important;
}

.ts-popup_body__profile .messages-form-input {
  background-color: #6b6556;
  border-radius: 50px;
}
.ts-popup_body__profile .form_textarea_message {
  color: white;
}
.ts-popup_body__profile .form_textarea_message::placeholder {
  color: white;
}

.ts-popup_body__profile .profile_description {
  background-color: #6b6556;
}
.ts-popup_body__profile .profile_description:after {
  background: #a09881;
}
.ts-popup_body__profile .user_description:before {
  border-left: 20px solid #6b6556;
}
.ts-popup_body__profile .profile_waiting__text {
  font-weight: 400;
}
.ts-popup_body__profile .user_description span {
  color: white;
}

.ts-popup_body__profile .report_block {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-popup_body__profile .report_block__item:hover {
  background-color: #6b6556;
}

.ts-my-profile-popup_body {
  background-color: #a09881;
}
.ts-my-profile-popup_body .popup_head, .ts-search-settings .popup_content, .ts-search-settings .popup_head {
  background-color: #a09881;
}
.ts-my-profile-popup_body .popup_content {
  border-top: solid 0.5px #a09881;
  border-bottom: solid 0.5px #a09881;
}
.ts-my-profile-popup_body .popup_footer, .ts-search-settings .popup_footer, .ts-search-settings .edit-container {
  background-color: #403d34;
}

.ts-search-settings svg {
  stroke: #fff;
}

.ts-search-settings .btn_form {
    background-color: #f7b500;
}

.ts-search-settings .btn_form:hover {
    background-color: #f7a900;
}

.ts-search-settings .select-menu {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}

.ts-search-settings .button_placeholder {
  color: #fff;
}

.ts-search-settings .input-range__slider, .ts-search-settings .input-range__track--active {
  background: #f7b500;
  border: 1px solid #f7b500;
}

.ts-search-settings .btn_filter {
  color: #6E7478;
}

.ts-my-profile-popup_body .settings-subscription__content,
.ts-my-profile-popup_body .settings-subscription__text {
    color: #000;
  }

.ts-my-profile-popup_body .btn_primary {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body .btn_favorites {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body .btn_chat {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body .btn_chat:hover {
  background-color: #f7a900 !important;
}
.ts-my-profile-popup_body .btn_favorites:hover {
  background-color: #f7a900 !important;
}
.ts-my-profile-popup_body .btn_primary:hover {
  background-color: #f7a900 !important;
}

.ts-my-profile-popup_body .messages-form-input {
  background-color: #6b6556;
  border-radius: 50px;
}
.ts-my-profile-popup_body .form_textarea_message {
  color: white;
}
.ts-my-profile-popup_body .form_textarea_message::placeholder {
  color: white;
}

.ts-my-profile-popup_body .profile_description {
  background-color: #6b6556;
}
.ts-my-profile-popup_body .profile_description:after {
  background: #a09881;
}
.ts-my-profile-popup_body .user_description:before {
  border-left: 20px solid #6b6556;
}
.ts-my-profile-popup_body .profile_waiting__text {
  font-weight: 400;
}
.ts-my-profile-popup_body .user_description span {
  color: white;
}

.ts-my-profile-popup_body .my-profile__actions-menu {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-my-profile-popup_body .footer__link {
  color: #fff;
}
.ts-my-profile-popup_body .report_block__item:hover {
  background-color: #6b6556;
}
.ts-my-profile-popup_body .my-profile__coins {
  color: white;
  fill: white;
  cursor: pointer;
  display: inline-block;
}
.ts-my-profile-popup_body .my-profile__coins:hover {
  color: #f7b500;
  fill: #f7b500;
}
.ts-my-profile-popup_body .my-profile__coins:active {
  color: #f7b500;
  fill: #f7b500;
}

.ts-my-profile-popup_body .row-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  fill: #fff;
  transition: fill, color 0.3s ease;
}
.ts-my-profile-popup_body .row-action:hover {
  fill: #f7b500;
  transition: fill, color 0.3s ease;
}
.ts-my-profile-popup_body .row-action:hover span {
  color: #f7b500;
}

.ts-my-profile-popup_body .row-action:active {
  fill: #f7b500;
  transition: fill, color 0.3s ease;
}
.ts-my-profile-popup_body .row-action:active span {
  color: #f7b500;
}
.ts-my-profile-popup_body .row-action span {
  display: block;
  margin-left: 10px;
  color: #fff;
  font-weight: 600;
}
.ts-my-profile-popup_body .collection_col_left {
  color: #fff;
  fill: #fff;
}

.ts-my-profile-popup_body .collection_col_arrow {
  stroke: #fff;
}

.ts-my-profile-popup_body .collection_row:hover .collection_col_arrow {
  stroke: #f7b500;
}

.ts-my-profile-popup_body .collection_row:active .collection_col_arrow {
  stroke: #f7b500;
}

.ts-my-profile-popup_body .collection_row:hover .collection_col_left {
  color: #f7b500;
  fill: #f7b500;
}

.ts-my-profile-popup_body .collection_row:active .collection_col_left {
  color: #f7b500;
  fill: #f7b500;
}
.ts-my-profile-popup_body .load-photo__btn {
  background-color: #f7b500;
}
.ts-my-profile-popup_body .load-photo__btn:hover {
  background-color: #6b6556;
}

.ts-footer .download {
  align-items: center;
}

.ts-footer .download__text {
  color: #a09881;
}

.ts-footer .footer__links {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 52px auto 40px;
  flex-wrap: wrap;
}
.ts-footer .footer__link:hover {
  text-decoration: underline;
}

.ts-footer .footer__link {
  font-size: 16px;
  color: #f7b500;
  text-decoration: none;
  transition: color 0.3s ease;
  padding-right: 15px;
  margin-bottom: 5px;
}

.ts-footer .footer__link:last-child {
  padding-left: 0;
  margin-bottom: 0;
}

.ts-footer .footer__link:hover {
  color: #f7b500;
}

.ts-footer .footer__link:active {
  color: #f7b500;
}

.ts-popup-purchase {
  background-color: #242526 !important;
}
.ts-popup-purchase .purchase-coins {
  background-color: #f7b500;
}
.ts-popup-purchase .purchase-coins:hover {
  background-color: #f7b500;
}
.ts-popup-purchase .purchase-coins__coins {
  color: #000000;
}
.ts-popup-purchase .purchase-coins__sum {
  color: #000000;
}
.ts-popup-purchase .popup-purchase__agree a {
  color: #a09881;
}
.ts-popup-purchase .popup-purchase__agree a:hover {
  text-decoration: underline;
}
.ts-popup-purchase .btn_close {
  fill: #fff !important;
}
.ts-popup-purchase .btn_close:hover {
  fill: #fff !important;
}
.ts-popup-purchase .purchase-sub__container {
  background-color: #f7b500;
  color: #000000;
}
.ts-popup-purchase .purchase-sub__container:hover {
  background-color: #f7b500;
}
.ts-popup-purchase .purchase-sub__container_trial {
  background-color: #fff;
  border: none;
}
.ts-popup-purchase .purchase-sub__container_trial:hover {
  background-color: #fff;
  border: none;
  color: #000000;
}
.ts-popup-purchase .purchase-sub__benefit {
  color: #000000;
  border: 1px solid #000;
}
.ts-popup-purchase .discount-text {
  color: #fff !important;
}
.ts-popup-purchase .purchase-sub__benefit_trial {
  background-color: #b620e0;
  color: #fff;
}
.ts-popup-trial {
  background-color: #242526 !important;
}
.ts-popup-trial .btn_trial {
  background-color: #f7b500 !important;
}
.ts-popup-trial .btn_trial:hover {
  background-color: #f7a900 !important;
}
.ts-bottom-menu-wrap {
  background-color: rgb(50, 47, 40) !important;
}
.ts-bottom-menu-wrap .bottom-menu__item {
  color: #a09881;
  fill: #a09881;
}
.ts-bottom-menu-wrap .bottom-menu__item.disable.active {
  color: #a09881;
}
.ts-bottom-menu-wrap .bottom-menu__item.disable.active svg {
  fill: #a09881!important;
}
.ts-bottom-menu-wrap .bottom-menu__item.active {
  color: #f7b500;
}
.ts-bottom-menu-wrap .bottom-menu__item.active svg {
  fill: #f7b500 !important;
}
.header_photo {
  width: 40px;
  height: 40px;
}
.header_photo.no_photo {
  background: #c3d2db url('/img/no-photo.svg') no-repeat center bottom;
  background-size: 40px;
  border-radius: 50%;
}
.ts-popup_body__profile_mobile {
  background-color: #000000 !important;
}

.ts-popup_body__profile_mobile .bg_down {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 80%);
}
.ts-popup_body__profile_mobile .mobile_profile_footer {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 50%);
}
.ts-name_text_wrap {
  position: absolute;
  left: 20px;
  bottom: 55px;
  z-index: 2;
}
.ts-about_wrap {
  position: absolute;
  left: 20px;
  bottom: 85px;
  z-index: 2;
}
.ts-popup_body__profile_mobile .profile_title {
  font-size: 18px;
  line-height: 22px;
}
.ts-popup_body__profile_mobile .location_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.ts-popup_body__profile_mobile .about_mobile {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: left;
  width: 70%;
}
.ts-popup_body__profile_mobile .occupation__text {
  color: white;
}
.ts-popup_body__profile_mobile .activity_title {
  color: white;
}
.ts-popup_body__profile_mobile .btn_chat {
  background-color: #f7b500;
}
.ts-popup_body__profile_mobile .btn_chat:hover {
  background-color: #f7a900 !important;
}
.ts-popup_body__profile_mobile .activity_item {
  color: #a09881;
  background-color: #484338;
}
.ts-popup_body__profile_mobile .mobile_btn_close {
  background-color: rgb(17, 15, 13);
}

.ts-popup_body__profile_mobile .report_block {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-popup_body__profile_mobile .report_block__item:hover {
  background-color: #6b6556;
}
.ts-my-profile-popup_body_mobile {
  background-color: #000 !important;
}
.ts-my-profile-popup_body_mobile .bg_down {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 80%);
}
.ts-my-profile-popup_body_mobile .mobile_profile_footer {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 50%);
}
.ts-my-profile-popup_body_mobile .mobile_upload {
  background-color: #6b6556;
}
.ts-my-profile-popup_body_mobile .mobile_upload_circle {
  background-color: #a09881;
}
.ts-my-profile-popup_body_mobile .mobile_btn_close {
  background-color: rgb(17, 15, 13);
}

.ts-my-profile-popup_body_mobile .my-profile__actions-menu {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-my-profile-popup_body_mobile .footer__link {
  color: #fff;
}
.ts-my-profile-popup_body_mobile .report_block__item:hover {
  background-color: #6b6556;
}

.ts-my-profile-popup_body_mobile .btn_primary {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body_mobile .btn_favorites {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body_mobile .btn_chat {
  background-color: #f7b500;
  color: white;
}
.ts-my-profile-popup_body_mobile .btn_chat:hover {
  background-color: #f7a900 !important;
}
.ts-my-profile-popup_body_mobile .btn_favorites:hover {
  background-color: #f7a900 !important;
}
.ts-my-profile-popup_body_mobile .btn_primary:hover {
  background-color: #f7a900 !important;
}

.ts-my-profile-popup_body_mobile .messages-form-input {
  background-color: #6b6556;
  border-radius: 50px;
}
.ts-my-profile-popup_body_mobile .form_textarea_message {
  color: white;
}
.ts-my-profile-popup_body_mobile .form_textarea_message::placeholder {
  color: white;
}

.ts-my-profile-popup_body_mobile .profile_description {
  background-color: #6b6556;
}
.ts-my-profile-popup_body_mobile .profile_description:after {
  background: #a09881;
}
.ts-my-profile-popup_body_mobile .user_description:before {
  border-left: 20px solid #6b6556;
}
.ts-my-profile-popup_body_mobile .profile_waiting__text {
  font-weight: 400;
}
.ts-my-profile-popup_body_mobile .user_description span {
  color: white;
}

.ts-my-profile-popup_body_mobile .row-action span {
  color: #fff;
}
.ts-my-profile-popup_body_mobile .row-action {
  fill: #fff;
}
.ts-my-profile-popup_body_mobile .row-action:hover span {
  color: #f7b500;
}
.ts-my-profile-popup_body_mobile .row-action:hover {
  fill: #f7b500;
}
.ts-my-profile-popup_body_mobile .about_mobile {
  background-color: #a09881;
  text-align: left;
  width: 70%;
}
.ts-notifications {
  background-color: #a09881;
}
.ts-notifications .dialog_content {
  justify-content: center;
}
.ts-notifications .dialog.new {
  background-color: #f7b500;
}
.ts-edit-profile {
  background-color: #a09881;
}
.ts-edit-profile .popup_head {
  background-color: #a09881;
}
.ts-edit-profile .popup_content {
  border-top: solid 0.5px #a09881;
  border-bottom: solid 0.5px #a09881;
}
.ts-edit-profile .popup_footer {
  background-color: #403d34;
}
.ts-edit-profile .collection_col_left {
  color: #fff;
  fill: #fff;
}

.ts-edit-profile .collection_col_arrow {
  stroke: #fff;
}

.ts-edit-profile .collection_row:hover .collection_col_arrow {
  stroke: #f7b500;
}

.ts-edit-profile .collection_row:active .collection_col_arrow {
  stroke: #f7b500;
}

.ts-edit-profile .collection_row:hover .collection_col_left {
  color: #f7b500;
  fill: #f7b500;
}

.ts-edit-profile .collection_row:active .collection_col_left {
  color: #f7b500;
  fill: #f7b500;
}
.ts-edit-profile .load-photo__btn {
  background-color: #f7b500;
}
.ts-edit-profile .load-photo__btn:hover {
  background-color: #6b6556;
}
.ts-edit-profile .btn_form {
  background-color: #f7b500;
  color: white;
}
.ts-edit-profile .btn_form:hover {
  background-color: #f7a900 !important;
}

.ts-edit-profile .activities_item {
  background-color: #6b6556;
  color: white;
}
.ts-edit-profile .activities_item:hover {
  background-color: #302e2a;
}
.ts-edit-profile .activities_item.active {
  background-color: #000;
  color: #f7b500;
}
.ts-form_control .form_input {
  background-color: #6b6556;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
}
.ts-form_control .form_input:hover {
  background-color: #36342e !important;
}
.ts-popup_body__profile .form_input:hover {
  background-color: #36342e !important;
}
.ts-form_control .form_input::placeholder {
  color: #fff;
}
.ts-form_control .form_input:hover {
  background-color: #6b6556;
}
.ts-form_control .form_input.invalid {
  border: 1px solid #ec0000;
}
.ts-form_input_area {
  background-color: #6b6556;
  border: 1px solid #fff;
  border-radius: 23px;
  color: #fff;
}
.ts-form_input_area:hover {
  background-color: #383732;
}
.ts-form_input_area::placeholder {
  color: #fff;
}
.ts-item_select .form_input {
  background-color: #6b6556;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
}
.ts-item_select .form_input:hover {
  background-color: #36342e !important;
}
.ts-item_select .form_input::placeholder {
  color: #fff;
}

.ts-item_select .form_input:hover {
  background-color: #6b6556;
}
.ts-item_select .form_input.invalid {
  border: 1px solid #ec0000;
}
.ts-item_select .select-menu-single {
  background-color: #fff;
  color: #242526;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-item_select .select-menu-single__item:hover {
  background-color: #6b6556;
  color: #fff;
}

.ts-lang_select .form_input {
  background-color: #6b6556;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
}
.ts-lang_select .form_input:hover {
  background-color: #36342e !important;
}
.ts-lang_select .form_input::placeholder {
  color: #fff;
}
.ts-lang_select .form_input:hover {
  background-color: #6b6556;
}
.ts-lang_select .form_input.invalid {
  border: 1px solid #ec0000;
}
.ts-lang_select .select-menu,
.ts-form_control .select-menu {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-confirm .confirm__title {
  color: #000;
}
.ts-confirm .confirm__text {
  color: #000;
}
.ts-confirm .delete_btn {
  background-color: #f7b500 !important;
}
.ts-confirm .btn_form:hover {
  background-color: #f7a900 !important;
}
.ts-main-bg {
  background: url('/img/main_bg_ts.png') no-repeat center center;
  background-size: 1136px;
}
.ts-start_page .text--light {
  color: #fff;
}
.ts-start_page .card_form {
  background-color: #a09881;
}
.ts-start_page .btn_form {
  background-color: #f7b500;
}
.ts-start_page .btn_form:hover {
  background-color: #f7a900;
}
.ts-start_page .btn_form:disabled {
  background-color: #8f8976;
  color: #403d34;
}
.ts-start_page .btn_primary {
  background-color: #f7b500;
}
.ts-start_page .btn_primary:hover {
  background-color: #f7a900;
}
.ts-start_page .text_agree {
  color: #fff;
}
.ts-start_page .text_agree a {
  color: #fff;
  text-decoration: underline;
}
.ts-start_page .validation_label {
  background-color: #ec0000;
  border-radius: 50px;
  color: #fff;
  padding: 5px 10px;
  font-weight: 400;
}
.ts_btn_in {
  color: #f7b500 !important;
}
.ts_btn_in:hover {
  color: #bb8000 !important;
}
.ts-popup-sign-in {
  background-color: #a09881;
}
.ts-popup-sign-in .btn_form {
  background-color: #f7b500;
}
.ts-popup-sign-in .btn_form:hover {
  background-color: #f7a900;
}
.ts-popup-sign-in .btn_form:disabled {
  background-color: #8f8976;
  color: #403d34;
}
.ts-popup-sign-in .btn_link {
  color: #fff;
}
.ts-step_form {
  background-color: #a09881;
}
.ts-step_form .btn_form {
  background-color: #f7b500;
}
.ts-step_form .btn_form:hover {
  background-color: #f7a900;
}
.ts-step_form .btn_form:disabled {
  background-color: #8f8976;
  color: #403d34;
}
.ts-popup_body__profile .btn_primary:disabled {
  background-color: #8f8976;
  color: #403d34;
}
.ts-popup_body__profile .profile_actions .btn_link:hover {
  color: #5a5958 !important;
}
.ts-popup_body__profile .btn_primary:disabled:hover {
  background-color: #8f8976 !important;
  color: #403d34;
}
.ts-step_form .btn_photo {
  background-color: #6b6556;
}
.ts-step_form .btn_link {
  color: white;
}
.ts-step_form .btn_link:hover {
  color: rgb(87, 82, 82);
}
.ts-step_form .activities_item {
  background-color: #6b6556;
  color: white;
}
.ts-step_form .activities_item:hover {
  background-color: #302e2a;
}
.ts-step_form .activities_item.active {
  background-color: #000;
  color: #f7b500;
}
.ts-messages-area__head .report_block {
  background-color: #a09881;
  box-shadow: 0 0 4px 0 #403d34;
  border: 1px solid #403d34;
}
.ts-messages-area__head .report_block__item:hover {
  background-color: #6b6556;
}
.ts-edit-profile .popup_content {
  background-color: #a09881;
}
.ts-messages-area__container .btn_trial,
.ts-messages-area__container .btn_favorites,
.ts-messages-area__container .btn_upgrade {
  background-color: #b620e0;
}
.ts-messages-area__container .btn_trial:hover,
.ts-messages-area__container .btn_favorites:hover,
.ts-messages-area__container .btn_upgrade:hover {
  background-color: #ca31f5;
}
.ts-messages-area__container .limit__action,
.ts-messages-area__container .limit__cost {
  color: white;
}
.ts-messages-area__container .chat_ask li {
  background-color: rgba(138, 120, 80, 0.7);
  color: #a09881;
}
.ts-bottom-menu-wrap .bottom-menu__item svg {
  fill: #a09881;
}
.profile_gallery_item{
  cursor: pointer;
}
.ts-popup-purchase .purchase-coins:hover {
  background-color: #f7a900;
}
.ts-popup-purchase .purchase-sub__container:hover {
  background-color: #f7a900;
}
.ts-popup-purchase .purchase-sub__container_trial:hover {
  background-color: #b620e0;
  color: white !important;
}
.ts-confirm .confirm__container{
  background-color: black !important;
}
.ts-confirm .confirm__title {
  color: white !important;
}
.ts-confirm .confirm__text {
  color: white !important;
}